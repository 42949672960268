import { atom } from 'recoil';

export const deliverySelectPopupOpenState = atom<boolean>({
  default: false,
  key: 'deliverySelectPopupOpen',
});

export const loaderOpenState = atom<boolean>({
  default: false,
  key: 'loaderOpen',
});
