import { MessageComponentProps } from '@sprinx/react-globalize/types';
import comOrdersMessages from '@sprinx/react-i18n/react-com-orders/de';
import comMessages from '@sprinx/react-i18n/react-com/de';
import formsMessages from '@sprinx/react-i18n/react-forms/de';
import compMessages from '@sprinx/react-i18n/react-mui-components/de';
import fieldMessages from '@sprinx/react-i18n/react-mui-fields/de';
import pagesMessages from '@sprinx/react-i18n/react-mui-pages/de';
import tablesMessages from '@sprinx/react-i18n/react-mui-tables/de';
import shoppingCartMessages from '@sprinx/react-i18n/react-shopping-cart/de';
import shoppingListMessages from '@sprinx/react-i18n/react-shopping-lists/de';
import messages from '../i18n/de';

function MessagesDe({ children }: MessageComponentProps): JSX.Element {
  return children([
    compMessages,
    fieldMessages,
    tablesMessages,
    formsMessages,
    pagesMessages,
    comMessages,
    shoppingListMessages,
    shoppingCartMessages,
    comOrdersMessages,
    messages,
  ]);
}

export default MessagesDe;
