import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { useRecoilState } from 'recoil';
import { deliverySelectPopupOpenState } from '../../state/atoms';
import PickDeliveryPlace from '../PickDeliveryPlace';

export type DeliverySelectPopupProps = {
  //
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

const DeliverySelectPopup: FC<DeliverySelectPopupProps> = () => {
  const classes = useStyles();
  const [open, setOpen] = useRecoilState(deliverySelectPopupOpenState);

  return (
    <Dialog className={classes.root} maxWidth='sm' fullWidth open={open}>
      <DialogContent>
        <PickDeliveryPlace extraOnChange={() => setOpen(false)} />
      </DialogContent>
    </Dialog>
  );
};

export default DeliverySelectPopup;
