import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';

const useStyles = makeStyles({
  loadingContainer: {
    zIndex: 99999,
  },
});

export type LoadingBackdropProps = {
  className?: string;
};

const LoadingBackdrop: FC<LoadingBackdropProps> = ({ className }) => {
  const classes = useStyles();

  return (
    <Backdrop open className={clsx(classes.loadingContainer, className)}>
      <CircularProgress color='primary' />
    </Backdrop>
  );
};

export default LoadingBackdrop;
