import React from 'react';
// @ts-ignore
import GlobalizeProvider from '@sprinx/react-globalize/GlobalizeProvider';
// import loadable from '@loadable/component';
// import ComponentLoader from './AppComponentLoader';
import CldrLocaleCs from '@sprinx/react-globalize/CldrLocaleCs';
import CldrLocaleEn from '@sprinx/react-globalize/CldrLocaleEn';
import CldrLocaleDe from '@sprinx/react-globalize/CldrLocaleDe';
import CldrLocaleHu from '@sprinx/react-globalize/CldrLocaleHu';
import CldrLocaleSk from '@sprinx/react-globalize/CldrLocaleSk';
import CldrLocaleSl from '@sprinx/react-globalize/CldrLocaleSl';
import CldrLocaleHr from '@sprinx/react-globalize/CldrLocaleHr';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { localeState } from './api/appState';
import { SupportedLocale } from './i18n/types';
import { supportedLocales } from './config';
import MessagesCs from './messages/MessagesCs';
import MessagesDe from './messages/MessagesDe';
import MessagesEn from './messages/MessagesEn';
import MessagesHu from './messages/MessagesHu';
import MessagesSk from './messages/MessagesSk';
import MessagesSl from './messages/MessagesSl';
import MessagesHr from './messages/MessagesHr';

// CLDRS
// const CldrLocaleCs = loadable(() => import('@sprinx/react-globalize/CldrLocaleCs'), { fallback: <ComponentLoader /> });

// Messages
// const MessagesCs = loadable(() => import('./MessagesCs'), { fallback: <ComponentLoader /> });

export interface AppGlobalizeProps {
  children: React.ReactNode;

  /**
   * Defaultni jazyk.
   */
  // defaultLocale?: string;

  // /**
  //  * Handler zmeny preferovaneho jazuku.
  //  *
  //  * Signatura: `(languageCode) => void`
  //  */
  // onPreferredLanguageChanged?: (languageCode: string) => void;

  // supportedLocales: string[] | { code: string }[];
}

const AppGlobalize: React.FC<AppGlobalizeProps> = ({
  children,
  // defaultLocale = 'en',
  // onPreferredLanguageChanged,
  // supportedLocales,
}) => {
  const [locale, setLocale] = useRecoilState(localeState);
  const history = useHistory();

  return (
    <GlobalizeProvider
      dontResolveLocale
      cldrs={{
        cs: CldrLocaleCs,
        en: CldrLocaleEn,
        hu: CldrLocaleHu,
        sk: CldrLocaleSk,
        de: CldrLocaleDe,
        hr: CldrLocaleHr,
        sl: CldrLocaleSl,
      }}
      messages={{
        cs: MessagesCs,
        en: MessagesEn,
        de: MessagesDe,
        hu: MessagesHu,
        sk: MessagesSk,
        hr: MessagesHr,
        sl: MessagesSl,
      }}
      defaultLocale={locale}
      supportedLocales={supportedLocales || ['cs']}
      onPreferredLocaleChanged={(newLocale) => {
        setLocale(newLocale as SupportedLocale);
        const urlWithoutLocation = window.location.pathname.slice(3);
        const url = `/${newLocale}${urlWithoutLocation}`;
        history.push(url);
      }}
    >
      {children}
    </GlobalizeProvider>
  );
};

export default AppGlobalize;
