import {
  FilterField,
  FilterFieldFull,
  QueryOrderSettings,
  QueryPaginationSettings,
  textContainsToQuery,
} from '@sprinx/query-builder';
import { Multicurrency } from '@sprinx/react-globalize/types';
import { atom, DefaultValue, selector, selectorFamily, useRecoilValue } from 'recoil';
import { MoleculerListResult, Product, ProductRecord, ProductTaxonomyType } from '../../@sprinx/knihovka-types';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import {
  buildListQuery,
  createListInitialSettings,
  ListCallParams,
} from '../../@sprinx/react-after-razzle/filteredLists';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import useTranslateWithFallback, { TranslateWithFallback } from '../../hooks/useTranslateWithFallback';
import {
  apiClientState,
  currencyState,
  decimalPrecisionState,
  localeState,
  pricesTypeState,
  regionState,
} from '../appState';
import { productTaxonomyTypesQuery } from './taxonomyTypes';
import transformProduct from './transformProduct';

export type CatalogueProduct = Product<
  ProductRecord<{
    cu3Price?: Multicurrency;
    originalId?: string;
    packSize?: number;
    packSizeTextCZ?: string;
    packSizeTextSK?: string;
    type?: string;
  }>,
  | 'displayNameAsName'
  | 'media'
  | 'parametersDisplay'
  | 'prices'
  | 'pricingExtensions'
  | 'adornments'
  | 'stockQuantity'
  | 'calculatedPrices'
> & {
  href: string;
};

export type VariantsProduct = Product<
  ProductRecord,
  | 'displayNameAsName'
  | 'parametersDisplay'
  | 'prices'
  | 'pricingExtensions'
  | 'adornments'
  | 'stockQuantity'
  | 'calculatedPrices'
>;

const startWithLocaleRegex = /^\/[A-Za-z]{2}\//;
const getPathWithoutLocale = (path: string) => {
  if (startWithLocaleRegex.test(path)) return path.slice(3);
  return path;
};

export const catalogueQuery = selector<MoleculerListResult<CatalogueProduct>>({
  key: 'catalogue',
  get: ({ get }) => {
    const initial = get(catalogueInitialState);
    if (initial) return initial;

    const apiClient = get(apiClientState);
    const locale = get(localeState);
    const currency = get(currencyState);
    const decimalPrecision = get(decimalPrecisionState);
    const enabledDynamicRules = undefined;
    const pricesType = get(pricesTypeState);
    const region = get(regionState);

    const params = get(catalogueCallParamsState);

    return getProductCatalogue(
      apiClient,
      locale,
      currency,
      decimalPrecision,
      enabledDynamicRules,
      pricesType,
      region,
      params,
    );
  },
});

export const catalogueCallParamsState = GlobalStateRegister.register(
  atom<ListCallParams>({
    key: 'catalogueCallParams',
    default: createListInitialSettings({
      orderBy: 'sku', // ['displayName', 'cs'].join('.'),
      filter: [() => ({ taxonomies: '/catalogue' })],
    }),
  }),
);

export const catalogueOrderByState = selector<QueryOrderSettings>({
  key: 'catalogueOrderBy',
  get: ({ get }) => {
    const params = get(catalogueCallParamsState);
    return params.order;
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(catalogueCallParamsState, (prev) => ({
        ...prev,
        order: newValue,
        pagination: {
          ...prev.pagination,
          page: 1,
        },
      }));
    }
  },
});

export const cataloguePaginationState = selector<Partial<QueryPaginationSettings & { total: number }>>({
  key: 'cataloguePagination',
  get: ({ get }) => {
    const params = get(catalogueCallParamsState);
    const queryResult = get(catalogueQuery);

    return {
      page: queryResult.page || params.pagination.rowsPerPage,
      rowsPerPage: queryResult.pageSize || params.pagination.rowsPerPage,
      total: queryResult.total,
    };
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(catalogueCallParamsState, (prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          ...newValue,
        },
      }));
    }
  },
});

export const catalogueFilterState = selector<CatalogueFilterFormValues>({
  key: 'catalogueFilter',
  get: ({ get }) => {
    const params = get(catalogueCallParamsState);

    const res = params.filter.reduce<CatalogueFilterFormValues>(
      (acc, ff) => {
        if (typeof ff !== 'function' && ff.field === 'searchText') return { ...acc, searchText: ff.value };
        else if (typeof ff === 'object' && typeof ff?.field !== 'undefined' && ff.field === 'extraTaxonomies')
          return { ...acc, extraTaxonomies: ff.value };
        return acc;
      },
      {
        searchText: undefined,
      },
    );

    return res;
  },
  set: ({ get, set }, newValue) => {
    const locale = get(localeState);

    if (!(newValue instanceof DefaultValue)) {
      set(catalogueCallParamsState, (prev): ListCallParams => {
        const nextFilter = [
          ...prev.filter.reduce<FilterField<any>[]>((acc, ff) => {
            if (typeof ff === 'function') return [...acc, ff];
            return acc;
          }, []),
          ...(!newValue.searchText
            ? []
            : [
                {
                  field: 'searchText',
                  operator: 'contains',
                  value: newValue.searchText,
                  toQuery: (v: string) => textContainsToQuery(`_all.${locale}`, v),
                } as FilterFieldFull<any>,
              ]),
          ...(!newValue.extraTaxonomies || newValue.extraTaxonomies.length === 0
            ? []
            : [
                {
                  field: 'extraTaxonomies',
                  operator: 'contains',
                  value: newValue.extraTaxonomies,
                } as FilterFieldFull<any>,
              ]),
        ];

        return {
          ...prev,
          filter: nextFilter,
          pagination: {
            ...prev.pagination,
            page: 1,
          },
        };
      });
    }
  },
});

export const catalogueGlobalFilterState = selector<CatalogueFilterFormValues>({
  key: 'catalogueGlobalFilter',
  get: ({ get }) => {
    const params = get(catalogueCallParamsState);

    const res = params.filter.reduce<CatalogueFilterFormValues>(
      (acc, ff) => {
        if (typeof ff !== 'function' && ff.field === 'globalSearchText') return { ...acc, searchText: ff.value };
        return acc;
      },
      {
        searchText: undefined,
      },
    );

    return res;
  },
  set: ({ get, set }, newValue) => {
    const locale = get(localeState);

    if (!(newValue instanceof DefaultValue)) {
      set(catalogueCallParamsState, (prev): ListCallParams => {
        const nextFilter = [
          ...prev.filter.reduce<FilterField<any>[]>((acc, ff) => {
            if (typeof ff === 'function') return [...acc, ff];
            return acc;
          }, []),
          ...(!newValue.searchText
            ? []
            : [
                {
                  field: 'globalSearchText',
                  operator: 'contains',
                  value: newValue.searchText,
                  toQuery: (v: string) => textContainsToQuery(`_all.${locale}`, v),
                } as FilterFieldFull<any>,
              ]),
        ];

        return {
          ...prev,
          filter: nextFilter,
          pagination: {
            ...prev.pagination,
            page: 1,
          },
        };
      });
    }
  },
});

export interface CatalogueFilterFormValues {
  extraTaxonomies?: string[];
  searchText: string | undefined;
}

export interface CatalogueFilterFormErrors {
  searchText?: string | undefined;
}

export interface CatalogueFilterFormTouched {
  searchText?: boolean | undefined;
}

export const catalogueViewModeState = GlobalStateRegister.register(
  atom<CatalogueView>({
    key: 'catalogueView',
    default:
      (typeof window !== 'undefined' &&
        window.localStorage &&
        (window.localStorage.getItem('catalogueView') as CatalogueView | undefined | null)) ||
      'row',
  }),
);

export type CatalogueView = 'row' | 'card';

export const catalogueInitialState = GlobalStateRegister.register(
  atom<MoleculerListResult<CatalogueProduct> | undefined>({
    key: 'catalogueInitialState',
    default: undefined, // createListDefaultResult<CatalogueProduct>(),
  }),
);

export type CatalogueListHandler = (/* filter */) => Promise<MoleculerListResult<CatalogueProduct>>;

export function getProductCatalogue(
  apiClient: ApiClient,
  locale: string,
  currency: string,
  decimalPrecision: undefined | number,
  enabledDynamicRules: undefined | string[],
  pricesType: undefined | 'B2B' | 'B2C' | 'B2BForeign',
  region: undefined | string,
  params: ListCallParams,
): ReturnType<CatalogueListHandler> {
  const query = buildListQuery(params, {
    currency,
    decimalPrecision,
    enabledDynamicRules,
    pricesType,
    region,
  });

  const { taxonomies, extraTaxonomies, ...other } = query.query || {};
  query.query =
    extraTaxonomies && taxonomies ? { $and: [{ taxonomies }, { taxonomies: extraTaxonomies }], ...other } : query.query;
  Object.entries(query.query).forEach(([x]) => {
    if (x.includes('all')) query.query.taxonomies = '/catalogue';
  });
  return apiClient
    .post<MoleculerListResult<Omit<CatalogueProduct, 'href' | 'parameters'>>, {}>('/v1/catalogue', query)
    .then((r) => ({
      ...r,
      rows: transformProduct(locale, r.rows),
    }))
    .catch((err) => {
      console.error("'getProductCatalogue' error", err);
      throw err;
    });
}

export function useUrlToTitleElements(path: string): ReturnType<typeof convertUrlToTitleElements> {
  const t = useTranslateWithFallback();
  const taxonomies = useRecoilValue(productTaxonomyTypesQuery);
  return convertUrlToTitleElements(taxonomies, path, t);
}

function convertUrlToTitleElements(
  taxonomies: ProductTaxonomyType[],
  path: string,
  t: TranslateWithFallback,
): { code: string; label: string }[] {
  const pathWithoutLocale = getPathWithoutLocale(path);

  if (pathWithoutLocale.startsWith('/catalogue')) {
    const codes = pathWithoutLocale
      .split('/')
      .slice(1)
      .reduce<string[]>((a, p) => [...a, `${a[a.length - 1] || ''}/${p}`], []);

    const ret = taxonomies
      .filter((t) => codes.includes(t.code))
      .sort((a, b) => a.code.length - b.code.length)
      .map(({ label, ...rest }) => ({ ...rest, label: t(label) || label?.[0]?.text || '' }));

    return ret;
  }

  if (pathWithoutLocale.startsWith('/special/campaign')) {
    return [{ code: '/campaign', label: 'Kampaně' }];
  }

  if (pathWithoutLocale.startsWith('/special/introduction')) {
    return [{ code: '/introduction', label: 'Novinky' }];
  }

  if (pathWithoutLocale.startsWith('/special/sale')) {
    return [{ code: '/sale', label: 'Výprodej' }];
  }

  if (pathWithoutLocale.startsWith('/special/iq2021')) {
    return [{ code: '/iq2021', label: 'IQ 2021' }];
  }

  return [];
}

export function convertUrlToFilterField(path: string): FilterField<any> {
  let code: string;
  const pathWithoutLocale = getPathWithoutLocale(path);

  if (pathWithoutLocale.startsWith('/catalogue')) {
    code = pathWithoutLocale;
  } else if (pathWithoutLocale.startsWith('/special/expiration')) {
    code = '/expiration';
  } else if (pathWithoutLocale.startsWith('/special/sale')) {
    code = '/sale';
  } else if (pathWithoutLocale.startsWith('/special/campaign')) {
    code = '/campaign';
  } else if (pathWithoutLocale.startsWith('/special/introduction')) {
    code = '/introduction';
  } else if (pathWithoutLocale.startsWith('/special/vet-exclusive')) {
    code = '/vet-exclusive';
  } else if (pathWithoutLocale.startsWith('/special/iq2021')) {
    code = '/iq2021';
  }

  return () => ({ taxonomies: code });
}

export const productVariantsQuery = selectorFamily<MoleculerListResult<CatalogueProduct>, string>({
  key: 'productVariants',
  get:
    (pn) =>
    ({ get }) => {
      const apiClient = get(apiClientState);
      const currency = get(currencyState);
      const decimalPrecision = get(decimalPrecisionState);
      const enabledDynamicRules = undefined;
      const pricesType = get(pricesTypeState);
      const region = get(regionState);
      const locale = get(localeState);

      return getProductVariants(
        apiClient,
        locale,
        currency,
        decimalPrecision,
        enabledDynamicRules,
        pricesType,
        region,
        pn,
      );
    },
});

export function getProductVariants(
  apiClient: ApiClient,
  locale: string,
  currency: string,
  decimalPrecision: undefined | number,
  enabledDynamicRules: undefined | string[],
  pricesType: undefined | 'B2B' | 'B2C' | 'B2BForeign',
  region: undefined | string,
  pn: string,
): ReturnType<CatalogueListHandler> {
  return apiClient
    .post<MoleculerListResult<Omit<VariantsProduct, 'href' | 'parameters'>>, {}>('/v1/catalogue', {
      query: {
        pn: pn.toLowerCase(),
      },
      currency,
      decimalPrecision,
      enabledDynamicRules,
      pricesType,
      region,
    })
    .then((r) => ({
      ...r,
      rows: transformProduct(locale, r.rows),
    }))
    .catch((err) => {
      console.error("'getProductVariants' error", err);
      throw err;
    });
}
