import { MessageComponentProps } from '@sprinx/react-globalize/types';
import comOrdersMessagesHr from '@sprinx/react-i18n/react-com-orders/hr';
import comMessagesHr from '@sprinx/react-i18n/react-com/hr';
import formsMessagesHr from '@sprinx/react-i18n/react-forms/hr';
import compMessagesHr from '@sprinx/react-i18n/react-mui-components/hr';
import fieldMessagesHr from '@sprinx/react-i18n/react-mui-fields/hr';
import pagesMessagesHr from '@sprinx/react-i18n/react-mui-pages/hr';
import tablesMessagesHr from '@sprinx/react-i18n/react-mui-tables/hr';
import shoppingCartMessagesHr from '@sprinx/react-i18n/react-shopping-cart/hr';
import shoppingListMessagesHr from '@sprinx/react-i18n/react-shopping-lists/hr';
import messages from '../i18n/hr';

function MessagesHr({ children }: MessageComponentProps): JSX.Element {
  return children([
    compMessagesHr,
    fieldMessagesHr,
    tablesMessagesHr,
    formsMessagesHr,
    pagesMessagesHr,
    comMessagesHr,
    shoppingListMessagesHr,
    shoppingCartMessagesHr,
    comOrdersMessagesHr,
    messages,
  ]);
}

export default MessagesHr;
