import React from 'react';
import clsx from 'clsx';
import { withStyles, Theme } from '@material-ui/core/styles';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import ConfirmButton from '@sprinx/react-mui-components/DialogButtonConfirm';
import CancelButton from '@sprinx/react-mui-components/DialogButtonCancel';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';

export interface DialogConfirmProps extends Omit<DialogProps, 'children' | 'classes' | 'open' | 'title'> {
  /** Obsah dialogu => node .. */
  children?: React.ReactNode;

  /**
   * Pro CSS stylovani pres withStyle. Moznosti: content
   * @ignore
   */
  classes: Record<DialogConfirmClassKey, string>;

  confirmButtonLabel?: string;
  /** Funkce pro uzavreni dialogu. */
  onClose: () => void;

  /** Funkce pro potvrzeni dialogu. */
  onConfirm: () => void;

  open?: boolean;

  /** Nadpis dialogu */
  title?: React.ReactNode;
}

export type DialogConfirmClassKey = 'root' | 'content';

const styles = (theme: Theme) => ({
  root: {},
  content: theme.mixins.gutters({
    minWidth: theme.spacing(40),
  }),
});
/**
 * Dialog pro potvrzeni k dalsimu kroku <br>
 * Nutny local DialogForm
 */

const DialogConfirm: React.FC<DialogConfirmProps> = React.forwardRef(
  ({ children, classes, className, onClose, onConfirm, open = false, title, confirmButtonLabel, ...rest }, ref) => {
    const t = useTranslateWithFallback();
    const handleRequestClose = () => {
      if (onClose) {
        onClose();
      }
    };

    const handleRequestConfirm = () => {
      if (onConfirm) {
        onConfirm();
      }
    };

    return (
      <Dialog ref={ref} open={open} onClose={handleRequestClose} className={clsx(classes.root, className)} {...rest}>
        <DialogTitle>{title || t('app/actions/removeConfirmation')}</DialogTitle>
        <DialogContent className={classes.content}>
          <Typography component='div'>{children}</Typography>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleRequestClose} />
          <ConfirmButton onClick={handleRequestConfirm}>{confirmButtonLabel}</ConfirmButton>
        </DialogActions>
      </Dialog>
    );
  },
);

DialogConfirm.displayName = 'DialogConfirm';

export default withStyles(styles, { name: 'SxDialogConfirm' })(DialogConfirm);
