import { usePreferredLocale } from '@sprinx/react-globalize';
import { uniqBy } from 'ramda';
import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import { AdminContactPageDataDto, AdminStaticContentDto, AdminStaticPageListDto } from './api/adminContent';
import { Manufacturer } from './api/products/productManufacturers';
import { currencyState } from './api/appState';
import { supportedLocales } from './config';

export class AdminConfig {
  private static get root() {
    return (typeof window !== 'undefined' && window) || global;
  }
  public static get manufacturers(): Manufacturer[] | undefined {
    return this.root['manufacturers'];
  }
  static get mainInfoBox(): AdminStaticContentDto[] | undefined {
    return this.root['mainInfoBox'];
  }
  static get secondaryInfoBox(): AdminStaticContentDto[] | undefined {
    return this.root['secondaryInfoBox'];
  }
  static get imageSection(): AdminStaticContentDto[] | undefined {
    return this.root['imageSection'];
  }
  static get leftImageUnderCarousel(): AdminStaticContentDto[] | undefined {
    return this.root['leftImageUnderCarousel'];
  }

  static get rightImageUnderCarousel(): AdminStaticContentDto[] | undefined {
    return this.root['rightImageUnderCarousel'];
  }
  static get homepageMainImage(): AdminStaticContentDto[] | undefined {
    return this.root['homepageMainImage'];
  }
  static get carousel(): AdminStaticContentDto[] | undefined {
    return this.root['carousel'];
  }
  static get footerContactBox(): AdminStaticContentDto[] | undefined {
    return this.root['footerContactBox'];
  }
  static get footerHeadquarters(): AdminStaticContentDto[] | undefined {
    return this.root['footerHeadquarters'];
  }
  static get documentToDownload(): AdminStaticContentDto[] | undefined {
    return this.root['documentToDownload'];
  }
  static get staticPageList(): AdminStaticPageListDto | undefined {
    return this.root['staticPageList'];
  }
  static get contactPageData(): AdminContactPageDataDto | undefined {
    return this.root['contactPageData'];
  }
}

const useGetOneVisibleForLocale = (): ((
  item: AdminStaticContentDto[] | undefined,
) => AdminStaticContentDto | undefined) => {
  const locale = usePreferredLocale();
  const mapToFixedLocale = useMapToFixedLocale();

  return useCallback(
    (items: AdminStaticContentDto[] | undefined) => {
      if (!items) return;
      const item = items?.filter((i) => !i.hidden)?.find((i) => i.locales?.includes(locale));
      if (!item) return;

      return mapToFixedLocale(item);
    },
    [locale, mapToFixedLocale],
  );
};

const useGetVisibileForLocale = (): ((
  items: AdminStaticContentDto[] | undefined,
) => AdminStaticContentDto[] | undefined) => {
  const locale = usePreferredLocale();
  const mapToFixedLocale = useMapToFixedLocale();

  return useCallback(
    (items: AdminStaticContentDto[] | undefined) => {
      if (!items) return;
      return items?.filter((i) => !i.hidden && i.locales?.includes(locale)).map(mapToFixedLocale);
    },
    [locale, mapToFixedLocale],
  );
};

const useGetVisibleForCurrency = (): ((
  items: AdminStaticContentDto[] | undefined,
) => AdminStaticContentDto[] | undefined) => {
  const currency = useRecoilValue(currencyState);
  const mapToFixedLocale = useMapToFixedLocale();

  return useCallback(
    (items: AdminStaticContentDto[] | undefined) => {
      if (!items) return;
      return items?.filter((i) => !i.hidden && i.currency === currency).map(mapToFixedLocale);
    },
    [currency, mapToFixedLocale],
  );
};

const useMapToFixedLocale = (): ((item: AdminStaticContentDto) => AdminStaticContentDto) => {
  const locale = usePreferredLocale();

  const fixLocale = (url: string) => {
    const localeInUrl = supportedLocales.filter((i) => i !== locale).find((i) => url.includes(`/${i}/`));
    if (!localeInUrl) return url;

    return url.replace(`/${localeInUrl}/`, `/${locale}/`);
  };

  return useCallback(
    (item: AdminStaticContentDto) => ({
      ...item,
      url: item.url ? fixLocale(item.url) : undefined,
    }),
    [locale],
  );
};

export const useAdminConfig = () => {
  const locale = usePreferredLocale();

  const getVisibleForCurrency = useGetVisibleForCurrency();
  const getVisibileForLocale = useGetVisibileForLocale();
  const getOneVisibleForLocale = useGetOneVisibleForLocale();

  const documentToDownloadForLocale = getVisibileForLocale(AdminConfig.documentToDownload);
  const documentToDownloadForCurrency = getVisibleForCurrency(AdminConfig.documentToDownload);
  const allDocumentToDownload = uniqBy(
    (i) => i.id,
    [...(documentToDownloadForLocale || []), ...(documentToDownloadForCurrency || [])],
  );

  return {
    manufacturers: AdminConfig.manufacturers,
    mainInfoBox: getOneVisibleForLocale(AdminConfig.mainInfoBox),
    secondaryInfoBox: getOneVisibleForLocale(AdminConfig.secondaryInfoBox),
    leftImageUnderCarousel: getOneVisibleForLocale(AdminConfig.leftImageUnderCarousel),
    rightImageUnderCarousel: getOneVisibleForLocale(AdminConfig.rightImageUnderCarousel),
    homepageMainImage: getOneVisibleForLocale(AdminConfig.homepageMainImage),
    carousel: getVisibileForLocale(AdminConfig.carousel),
    footerContactBox: getOneVisibleForLocale(AdminConfig.footerContactBox),
    footerHeadquarters: getOneVisibleForLocale(AdminConfig.footerHeadquarters),
    documentToDownload: allDocumentToDownload,
    staticPageList: AdminConfig.staticPageList?.items?.filter((i) => i.locales?.includes(locale) && !i.hidden),
    contactPageData: AdminConfig.contactPageData,
  };
};

export const FTP_URL = 'https://pht.sxcdn.net';
