import { MessageComponentProps } from '@sprinx/react-globalize/types';
import comOrdersMessages from '@sprinx/react-i18n/react-com-orders/hu';
import comMessages from '@sprinx/react-i18n/react-com/hu';
import formsMessages from '@sprinx/react-i18n/react-forms/hu';
import compMessages from '@sprinx/react-i18n/react-mui-components/hu';
import fieldMessages from '@sprinx/react-i18n/react-mui-fields/hu';
import pagesMessages from '@sprinx/react-i18n/react-mui-pages/hu';
import tablesMessages from '@sprinx/react-i18n/react-mui-tables/hu';
import shoppingCartMessages from '@sprinx/react-i18n/react-shopping-cart/hu';
import shoppingListMessages from '@sprinx/react-i18n/react-shopping-lists/hu';
import messages from '../i18n/hu';

function MessagesHu({ children }: MessageComponentProps): JSX.Element {
  return children([
    compMessages,
    fieldMessages,
    tablesMessages,
    formsMessages,
    pagesMessages,
    comMessages,
    shoppingListMessages,
    shoppingCartMessages,
    comOrdersMessages,
    messages,
  ]);
}

export default MessagesHu;
