import React from 'react';
import { Route } from 'react-router-dom';
import useTranslateWithFallback from './hooks/useTranslateWithFallback';

export default function NotFoundErrorPage(): JSX.Element {
  const t = useTranslateWithFallback();

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 404;
        return <div>{t('notFoundPage/text')}</div>;
      }}
    />
  );
}
