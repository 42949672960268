import { TranslationDictionary, usePreferredLocale, useTranslate } from '@sprinx/react-globalize';
import { DefaultTranslationKey } from '../../../typings/translations';
import { SupportedLocale } from '../../i18n/types';
import { translateResource } from './helpers';

type TranslateOptions = {
  fallbackLocales?: string[];
  locale?: SupportedLocale;
};

export type TranslateWithFallback = {
  (resource: DefaultTranslationKey, options?: (Record<string, unknown> & TranslateOptions) | TranslateOptions): string;
  (resource: string, options?: (Record<string, unknown> & TranslateOptions) | TranslateOptions): string;
  (resource: TranslationDictionary, options?: (Record<string, unknown> & TranslateOptions) | TranslateOptions): string;
};

const defaultFallbackLocales = ['en', 'cs'];
const skFallbackLocales = ['cs', 'en'];

function getFallbackLocales(locale: string): string[] {
  if (locale === 'sk') return skFallbackLocales;
  return defaultFallbackLocales;
}

const useTranslateWithFallback = (): TranslateWithFallback => {
  const globalizedTranlsate = useTranslate();
  const preferredLocale = usePreferredLocale();

  return (
    resource: DefaultTranslationKey | string | TranslationDictionary,
    options?: (Record<string, unknown> & TranslateOptions) | TranslateOptions,
  ) => {
    const { locale, fallbackLocales, ...params } = options ?? {};
    const usedLocale = locale ?? preferredLocale;
    const usedFallbackLocales = fallbackLocales ?? getFallbackLocales(usedLocale);

    const text = translateResource(resource, usedLocale, params);
    if (text !== undefined) return text;

    for (const fallbackLocale of usedFallbackLocales) {
      const text = translateResource(resource, fallbackLocale, params);
      if (text !== undefined) return text;
    }

    try {
      return globalizedTranlsate(resource, { locale: usedLocale });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Translation not found for ${resource} in ${usedLocale} and fallbacks.`);
      return typeof resource === 'string' ? resource : '';
    }
  };
};

export default useTranslateWithFallback;
