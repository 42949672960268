import { useMemo } from 'react';
import { useRecoilValueLoadable, useRecoilValue } from 'recoil';
import { isMasterScopeState } from '../api/appState';
import { deliveryPlacesForSlave } from '../api/deliveryPlaces';
import { shoppingCustomerDeliveryPlacesSelector } from '../api/shopping';

const usePlaces = () => {
  const deliveryPlacesLoadable = useRecoilValueLoadable(deliveryPlacesForSlave);
  const placesFromUser = useRecoilValue(shoppingCustomerDeliveryPlacesSelector);
  const isMasterScope = useRecoilValue(isMasterScopeState);

  const places = useMemo(() => {
    if (deliveryPlacesLoadable.state === 'hasValue' && !isMasterScope) {
      return deliveryPlacesLoadable.contents || placesFromUser || [];
    }
    return placesFromUser || [];
  }, [deliveryPlacesLoadable, isMasterScope, placesFromUser]);

  return places;
};

export default usePlaces;
