import { MessageComponentProps } from '@sprinx/react-globalize/types';
import comOrdersMessages from '@sprinx/react-i18n/react-com-orders/sk';
import comMessages from '@sprinx/react-i18n/react-com/sk';
import formsMessages from '@sprinx/react-i18n/react-forms/sk';
import compMessages from '@sprinx/react-i18n/react-mui-components/sk';
import fieldMessages from '@sprinx/react-i18n/react-mui-fields/sk';
import pagesMessages from '@sprinx/react-i18n/react-mui-pages/sk';
import tablesMessages from '@sprinx/react-i18n/react-mui-tables/sk';
import shoppingCartMessages from '@sprinx/react-i18n/react-shopping-cart/sk';
import shoppingListMessages from '@sprinx/react-i18n/react-shopping-lists/sk';
import messages from '../i18n/sk';

function MessagesSk({ children }: MessageComponentProps): JSX.Element {
  return children([
    compMessages,
    fieldMessages,
    tablesMessages,
    formsMessages,
    pagesMessages,
    comMessages,
    shoppingListMessages,
    shoppingCartMessages,
    comOrdersMessages,
    messages,
  ]);
}

export default MessagesSk;
