import { MessageComponentProps } from '@sprinx/react-globalize/types';
import comOrdersMessages from '@sprinx/react-i18n/react-com-orders/en';
import comMessages from '@sprinx/react-i18n/react-com/en';
import formsMessages from '@sprinx/react-i18n/react-forms/en';
import compMessages from '@sprinx/react-i18n/react-mui-components/en';
import fieldMessages from '@sprinx/react-i18n/react-mui-fields/en';
import pagesMessages from '@sprinx/react-i18n/react-mui-pages/en';
import tablesMessages from '@sprinx/react-i18n/react-mui-tables/en';
import shoppingCartMessages from '@sprinx/react-i18n/react-shopping-cart/en';
import shoppingListMessages from '@sprinx/react-i18n/react-shopping-lists/en';
import messages from '../i18n/en';

function MessagesEn({ children }: MessageComponentProps): JSX.Element {
  return children([
    compMessages,
    fieldMessages,
    tablesMessages,
    formsMessages,
    pagesMessages,
    comMessages,
    shoppingListMessages,
    shoppingCartMessages,
    comOrdersMessages,
    messages,
  ]);
}

export default MessagesEn;
