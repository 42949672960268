import { IntlMessageFormat } from 'intl-messageformat';
import { TranslationDictionary } from '@sprinx/react-globalize';
import { path } from 'ramda';
import en from '../../i18n/en';
import cs from '../../i18n/cs';
import sk from '../../i18n/sk';
import de from '../../i18n/de';
import hu from '../../i18n/hu';

const messages = {
  en: en.en,
  cs: cs.cs,
  hu: hu.hu,
  de: de.de,
  sk: sk.sk,
};

function getKeyText(key: string, locale: string): string | undefined {
  const keyPath = key.replace(/\//g, '.').split('.');
  const text = path<string>(keyPath, messages[locale] ?? {});
  return text;
}

function getDictionaryText(dictionary: TranslationDictionary, locale: string): string | undefined {
  const translatedText = dictionary.find((i) => i.language === locale);
  const text = translatedText?.text;
  return text;
}

export function translateResource(
  resource: string | TranslationDictionary,
  locale: string,
  params?: Record<string, unknown>,
): string | undefined {
  const text = typeof resource === 'string' ? getKeyText(resource, locale) : getDictionaryText(resource, locale);
  if (text === undefined) return undefined;

  try {
    const msgFormatter = new IntlMessageFormat(text, 'en');
    return msgFormatter.format(params) as string;
  } catch (error) {
    console.error(`Formatter error for ${resource} in ${locale}.\n`, error);
    return typeof resource === 'string' ? resource : '';
  }
}
