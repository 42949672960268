import { QueryOrderSettings, QueryPaginationSettings, TableFilterField } from '@sprinx/query-builder';
import { atom, DefaultValue, selector } from 'recoil';
import { MoleculerListResult } from '../../@sprinx/knihovka-types';
import { Invoice, InvoiceRecord } from '../../@sprinx/knihovka-types/invoices';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import {
  buildListQuery,
  createListInitialSettings,
  ListCallParams,
} from '../../@sprinx/react-after-razzle/filteredLists';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState, localeState } from '../appState';
import transformInvoice from './transformInvoice';

export type InvoiceListItem = Pick<
  Invoice<InvoiceRecord, 'contact'>,
  'id' | 'number' | 'invoiceDate' | 'totalWithTax' | 'currency'
> & {
  href: string;
};

export const invoicesQuery = selector<MoleculerListResult<InvoiceListItem>>({
  key: 'invoices',
  get: ({ get }) => {
    const initialState = get(invoicesInitialState);
    if (initialState) {
      return initialState;
    }

    const apiClient = get(apiClientState);
    const locale = get(localeState);
    const params = get(invoicesCallParamsState);
    get(invoicesReloadTrigger);

    return getInvoices(apiClient, locale, params);
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(invoicesReloadTrigger, (n) => n + 1);
    }
  },
});

export const invoicesCallParamsState = GlobalStateRegister.register(
  atom<ListCallParams>({
    key: 'invoicesCallParams',
    default: createListInitialSettings({
      rowsPerPage: 10,
      orderBy: 'number',
      orderDirection: 'desc',
    }),
  }),
);

export const invoicesOrderByState = selector<QueryOrderSettings>({
  key: 'invoicesOrderBy',
  get: ({ get }) => {
    const params = get(invoicesCallParamsState);
    return params.order;
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(invoicesCallParamsState, (prev) => ({
        ...prev,
        order: newValue,
        pagination: {
          ...prev.pagination,
          page: 1,
        },
      }));
    }
  },
});

export const invoicesPaginationState = selector<QueryPaginationSettings & { total: number }>({
  key: 'invoicesPagination',
  get: ({ get }): QueryPaginationSettings & { total: number } => {
    const params = get(invoicesCallParamsState);
    const queryResult = get(invoicesQuery);

    return {
      page: queryResult.page || params.pagination.rowsPerPage,
      rowsPerPage: queryResult.pageSize || params.pagination.rowsPerPage,
      total: queryResult.total,
    };
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(invoicesCallParamsState, (prev) => ({
        ...prev,
        pagination: {
          // ...prevValue.pagination,
          ...newValue,
        },
      }));
    }
  },
});

export const invoicesFilterState = selector<TableFilterField<any>[]>({
  key: 'invoicesFilter',
  get: ({ get }) => {
    const params = get(invoicesCallParamsState);
    return (params.filter as TableFilterField<any>[]) || [];
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      const nextFilter = newValue.filter(Boolean);

      set(invoicesCallParamsState, (prev) => ({
        ...prev,
        filter: nextFilter,
        pagination: {
          ...prev.pagination,
          page: 1,
        },
      }));
    }
  },
});

export const invoicesInitialState = GlobalStateRegister.register(
  atom<MoleculerListResult<InvoiceListItem> | undefined>({
    key: 'invoicesInitialState',
    default: undefined, // createListDefaultResult<InvoicesProduct>(),
  }),
);

export function getInvoices(
  apiClient: ApiClient,
  locale: string,
  params: ListCallParams,
): Promise<MoleculerListResult<InvoiceListItem>> {
  return apiClient
    .get<MoleculerListResult<Omit<InvoiceListItem, 'href'>>, Record<string, any>>(
      '/v1/invoices',
      buildListQuery(params, { fields: ['id', 'number', 'invoiceDate', 'totalWithTax', 'currency'] }),
    )
    .then((r) => ({
      ...r,
      rows: transformInvoice(locale, r.rows),
    }));
}

export const invoicesReloadTrigger = atom<number>({
  key: 'invoicesReloadTrigger',
  default: 0,
});
