import { atom, constSelector, selector, useRecoilValue } from 'recoil';
import { ApiClient, createApiClient } from '../@sprinx/react-after-razzle';
import { ApplicationAuthState, GlobalStateRegister } from '../@sprinx/react-after-razzle/stateStore';
import { SupportedLocale } from '../i18n/types';
import { anonymouseUserId, locale, pricesType, region, supportedCurrencies, supportedLocales } from '../config';

// export const currencyState = GlobalStateRegister.register(
//   atom<string>({
//     key: 'currency',
//     default: appSettings.currency,
//   }),
// );

export const customerRegionState = selector<string>({
  key: 'customerRegionState',
  get: ({ get }) => {
    const auth = get(authState);

    return auth?.profile?.customer.extra.customerRegion || 'CZ';
  },
});

export const customerIdState = selector<string>({
  key: 'customerIdState',
  get: ({ get }) => {
    const auth = get(authState);
    return auth?.profile?.customer.customerId;
  },
});

export const currencyState = selector<string>({
  key: 'currencyState',
  get: ({ get }) => {
    const auth = get(authState);

    return auth?.profile?.customer.currency || 'CZK';
  },
});

export const paymentTypeLevelState = selector<number | null>({
  key: 'paymentTypeLevelState',
  get: ({ get }) => {
    const auth = get(authState);
    const paymentTypeLevel = auth?.profile?.customer.extra.paymentTypeLevel;
    return typeof paymentTypeLevel === 'number' ? paymentTypeLevel : null;
  },
});

export const decimalPrecisionState = selector<number>({
  key: 'decimalPrecisionState',
  get: ({ get }) => {
    const currency = get(currencyState);

    return currency === 'EUR' ? 4 : 2;
  },
});

export const supportedCurrenciesState = constSelector<string[]>(supportedCurrencies);

export const localeState = GlobalStateRegister.register(
  atom<SupportedLocale>({
    key: 'locale',
    default: locale,
  }),
);

export const supportedLocaleCodesState = constSelector<string[]>(supportedLocales);

export const pricesTypeState = GlobalStateRegister.register(
  atom<'B2B' | 'B2C' | 'B2BForeign' | undefined>({
    key: 'pricesType',
    default: pricesType || 'B2C',
  }),
);

export const regionState = GlobalStateRegister.register(
  atom<'_default' | string>({
    key: 'region',
    default: region || '_default',
  }),
);

export const anonymouseUserIdState = GlobalStateRegister.register(
  atom<string | undefined>({
    key: 'anonymouseUserId',
    default: anonymouseUserId || 'anonymous',
  }),
);

export const authState = GlobalStateRegister.register(
  atom<ApplicationAuthState<any> | undefined>({
    key: 'auth',
    default: undefined,
  }),
);

export const isSSRState = GlobalStateRegister.register(
  atom<boolean>({
    key: 'isSSR',
    default: false,
  }),
);

export const isAuthenticatedState = selector<boolean>({
  key: 'isAuthenticated',
  get: ({ get }) => {
    const auth = get(authState);
    return Boolean(auth && auth.user && auth.isAuthenticated);
  },
});

export const isMasterScopeState = selector<boolean>({
  key: 'isMasterScope',
  get: ({ get }) => {
    const auth = get(authState);
    return Boolean(auth && auth.user && (auth.scope || []).includes('vrp:master'));
  },
});

export const authUserState = selector<ApplicationAuthState<any>['user']>({
  key: 'authUser',
  get: ({ get }) => {
    const auth = get(authState);
    return auth?.user;
  },
});

export const apiClientState = selector({
  key: 'apiClient',
  get: ({ get }) => {
    const auth = get(authState);
    return createApiClient({ accessToken: auth?.accessToken });
  },
});

/**
 * useApiClient
 */
export function useApiClient(): ApiClient {
  const auth = useRecoilValue(authState);
  return createApiClient({ accessToken: auth?.accessToken });
}
