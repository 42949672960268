import React, { FC, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useChangePreferredLocale, usePreferredLocale } from '@sprinx/react-globalize';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSetRecoilState } from 'recoil';
import { Typography } from '@material-ui/core';
import { SupportedLocale } from '../../../i18n/types';
import { supportedLocales } from '../../../config';
import { catalogueFilterState } from '../../../api/products/catalogue';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';

const useStyles = makeStyles(
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'column',
    },
    langWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 0,
    },
    button: {
      color: 'black',
      textTransform: 'none',
      fontWeight: 400,
      width: 'fit-content',
      minWidth: 'fit-content',
      fontSize: '1rem',
    },
  }),
);

const localeToCountryCode: Record<SupportedLocale, string> = {
  cs: 'cz',
  en: 'gb',
  de: 'de',
  hu: 'hu',
  sk: 'sk',
  hr: 'hr',
  sl: 'si',
};

const LanguageMenu: FC = () => {
  const t = useTranslateWithFallback();
  const styles = useStyles();
  const preferredLocale = usePreferredLocale();
  const onLocalChange = useChangePreferredLocale();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const setFilter = useSetRecoilState(catalogueFilterState);

  // const location = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (locale: SupportedLocale) => {
    setAnchorEl(null);
    onLocalChange(locale);
    if (locale !== preferredLocale) {
      setFilter((prev) => ({
        ...prev,
        extraTaxonomies: prev.extraTaxonomies
          ? prev.extraTaxonomies.filter((tax) => !tax.includes('/manufacturer/')) // remove manufacturer filter
          : undefined,
      }));
    }
  };
  // const isAuthenticated = useRecoilValue(isAuthenticatedState);
  // const isCatalogue = location.pathname.includes('/cs/catalogue');

  return (
    <Box className={clsx(styles.langWrapper)}>
      <Typography>{t('app/language')}</Typography>
      <Button className={styles.button} aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
        <ReactCountryFlag
          svg
          style={{
            fontSize: '1.5rem',
            height: '1.5rem',
            width: '1.5rem',
          }}
          countryCode={localeToCountryCode[preferredLocale]}
        />
      </Button>
      <Menu id='simple-menu' anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {supportedLocales.map((locale) => (
          <MenuItem key={locale} onClick={() => handleChange(locale)}>
            <ReactCountryFlag
              svg
              style={{
                fontSize: '1.5rem',
                height: '1.5rem',
                width: '1.5rem',
              }}
              countryCode={localeToCountryCode[locale]}
            />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default LanguageMenu;
