import { getSerializedData } from '@jaredpalmer/after';
import isObjectLike from 'lodash/isObjectLike';
import pick from 'lodash/pick';
import { MutableSnapshot, RecoilState, SerializableParam, useRecoilCallback } from 'recoil';
import invariant from 'tiny-invariant';
import { ApplicationSettings } from './types';

export const initializeAppState =
  <T extends Partial<AppInitializationState<any>>>(initialAppState?: T) =>
  (mutableSnapshot: MutableSnapshot): void => {
    const { set } = mutableSnapshot;

    if (!initialAppState) return;

    Object.entries(initialAppState).forEach(([key, value]) => {
      const a = GlobalStateRegister.get(key);
      setSnapshotStateHelper('initializeAppState', set, a, value);
    });
  };

export function useGetAppProps(): () => AppInitializationState<any> {
  const getAppProps = useRecoilCallback<[], AppInitializationState<any>>(({ set, snapshot }) => () => {
    const states = {} as Omit<AppInitializationState<any>, 'propagateToState'>;

    // @ts-ignore unstable Recoil API
    for (const modifiedAtom of snapshot.getNodes_UNSTABLE(/* {isModified: true} */)) {
      const atomLoadable = snapshot.getLoadable(modifiedAtom);
      states[modifiedAtom.key] = atomLoadable.contents;
    }

    const ret: AppInitializationState<any> = {
      ...states,
      propagateToState(data) {
        if (!data) return;

        Object.entries(data).forEach(([k, v]) => {
          const a = GlobalStateRegister.get(k);
          setSnapshotStateHelper('useGetAppProps', set, a, v);
        });
      },
    };

    return ret;
  });

  return getAppProps;
}

export function createAppState(
  applicationSettings: Partial<ApplicationSettings> | null | undefined,
  proto: {
    auth?: ApplicationAuthState<any>;
  },
): Partial<AppInitializationState<any>> & { $toPresave?: string[] } {
  return {
    ...applicationSettings,
    ...proto,
    presaveState(keys: string | string[]): void {
      this.$toPresave = [...(this.$toPresave || []), ...(Array.isArray(keys) ? keys : [keys])];
    },
    presaveStateGet() {
      return this.$toPresave || [];
    },
    propagateToState(data): void {
      if (!data) return;

      Object.entries(data).forEach(([k, v]) => {
        this[k] = v;
      });
    },
  };
}

export function restoreAppState(key: string): Partial<AppInitializationState<any>> {
  return getSerializedData(key) as Partial<AppInitializationState<any>>;
}

export function appStateToStore(
  state: Partial<AppInitializationState<any>>,
  // Not only defined keys of AppInitialState, but all other registered keys...
  pickKeys?: string[],
): Partial<AppInitializationState<any>> {
  return pick(state, ['auth', ...(pickKeys || [])]);
}

/**
 * STATES
 */
class GlobalStateRegisterMap {
  constructor() {
    this.data = {};
  }

  private data: Record<string, RecoilState<any> | ((param: any) => RecoilState<any>)>;

  public get(key: string): RecoilState<any> | ((param: any) => RecoilState<any>) | undefined {
    return this.data[key];
  }

  public register<T extends RecoilState<any>>(state: T): T;
  public register<P extends SerializableParam, T extends RecoilState<any>>(
    state: (params: P) => T,
    familyKey: string,
  ): GlobalStateRegisterAtomFamily<P, T>;
  public register(state: any, familyKey?: string): unknown {
    if (isObjectLike(state) && typeof state.key === 'string') {
      this.data[state.key] = state;
      return state as RecoilState<any>;
    } else if (typeof state === 'function' && state.length === 1) {
      invariant(familyKey, 'GlobalStateRegisterMap atomFamily registration requires familyKey as second parameter.');
      this.data[familyKey] = state as (param: any) => RecoilState<any>;
      (state as GlobalStateRegisterAtomFamily<any, any>).key = familyKey;
      return state as GlobalStateRegisterAtomFamily<any, any>;
    }

    throw new Error('GlobalStateRegisterMap register state not supports this type of state.');
  }
}
export const GlobalStateRegister = new GlobalStateRegisterMap();

const setSnapshotStateHelper = (
  msg: string,
  set: (recoilVal: RecoilState<any>, valOrUpdater: any) => void,
  a: undefined | RecoilState<any> | ((param: any) => RecoilState<any>),
  value: any,
): void => {
  if (a && isObjectLike(a)) {
    set(a as RecoilState<any>, value as any);
  } else if (a && typeof a === 'function') {
    invariant(
      Array.isArray(value) && value.length === 2,
      `${msg} requires tuple '[param: SerializableParam, value: any]' as initialization value.`,
    );

    const [param, initialValue] = value as [param: SerializableParam, value: any];
    set(a(param), initialValue);
  }
};

export interface AppInitializationState<Customer extends { id: string }> extends ApplicationSettings {
  auth?: ApplicationAuthState<Customer>;
  isSSR?: boolean;
  presaveState: PresaveStateHandler;
  presaveStateGet: () => string[];
  propagateToState: PropagateToStateHandler;
}

type PropagateToStateHandler = (data: Record<PropagateToInitialStateKey, any>) => void;
type PropagateToInitialStateKey = keyof Omit<AppInitializationState<any>, 'propagateToState'> | string;
type PresaveStateHandler = (keys: string | string[]) => void;

export interface ApplicationAuthState<Customer extends { id: string }> {
  accessToken: string;
  isAuthenticated: boolean;
  profile?: {
    customer: Customer;
    deliveryPlaces?: Customer[];
    orderTotalLimit: number | null;
    supervisor?: string | null;
  };
  scope?: string[];
  tokenPayload?: { exp: number } & Record<string, unknown>;
  user?: AppUser;
}

export interface AppUser {
  email?: string;
  id: string;
  name: string;
  phone?: string;
  region?: string;
  username?: string;
}

export interface GlobalStateRegisterAtomFamily<P extends SerializableParam, T extends RecoilState<any>> {
  (params: P): T;
  key: string;
}
